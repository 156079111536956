import * as React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const {
    mdx: {
      frontmatter: { title, description },
      body,
      excerpt,
    },
  } = data
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={title} description={description || excerpt} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <MDXRenderer>{body}</MDXRenderer>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
    </Layout>
  )
}

export default About
export const aboutPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { eq: "about-guide-projects/" }) {
      id
      excerpt
      body
      frontmatter {
        title
        description
        date
        category
      }
      wordCount {
        words
      }
      timeToRead
      slug
    }
  }
`
